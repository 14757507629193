import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

/* Guest Component */
const Login = () => import('../components/Login.vue' /* webpackChunkName: "resource/js/components/login" */)
const Register = () => import('../components/Register.vue' /* webpackChunkName: "resource/js/components/register" */)
const Main = () => import('../pages/Main.vue' /* webpackChunkName: "resource/js/components/register" */)
const DiscussionDetail = () => import('../pages/DiscussionDetail.vue' /* webpackChunkName: "resource/js/components/register" */)
const LeaderBaord = () => import('../pages/LeaderBoard.vue' /* webpackChunkName: "resource/js/components/register" */)
const MyFollowings = () => import('../pages/MyFollowings.vue' /* webpackChunkName: "resource/js/components/register" */)
const Settings = () => import('../pages/Settings.vue' /* webpackChunkName: "resource/js/components/register" */)
/* Guest Component */

/* Layouts */
const DahboardLayout = () => import('../components/layouts/HeaderNavBar.vue' /* webpackChunkName: "resource/js/components/layouts/dashboard" */)
/* Layouts */

/* Authenticated Component */
const Dashboard = () => import('../components/Dashboard.vue' /* webpackChunkName: "resource/js/components/dashboard" */)
/* Authenticated Component */


const Routes = [
    {
        name:"login",
        path:"/login",
        component:Login,
        meta:{
            middleware:"guest",
            title:`Login`
        }
    },
    {
        name:"logout",
        path:"/logout",
        component:Login,
        meta:{
            middleware:"guest",
            title:`Login`
        }
    },
    {
        name:"register",
        path:"/register",
        component:Register,
        meta:{
            middleware:"guest",
            title:`Register`
        }
    },
    {
        path:"/",
        component:Dashboard,
        meta:{
            middleware:"auth",
            title:`Threads`
        },
        children: [
            {
                name:"threads",
                path:"/threads/:course_code/:type",
                component:Main,
                meta:{
                    middleware:"auth",
                    title:`Threads`
                },
            },
            {
                name:"discussion",
                path: '/',
                component: Main,
                meta:{
                    middleware:"auth",
                    title:`Threads`
                }
            },
            {
                name:"myquestions",
                path:"/my-questions/:course_code",
                component:Main,
                meta:{
                    middleware:"auth",
                    title:`My Questions`
                }
            },
            {
                name:"threaddetail",
                path:"/thread-detail/:slug/:course_code/:type",
                component:DiscussionDetail,
                meta:{
                    middleware:"auth",
                    title:`Discussion Detail`
                }
            },
            {
                name:"leaderboard",
                path:"/thread/:course_code/:type",
                component:LeaderBaord,
                meta:{
                    middleware:"auth",
                    title:`Leaderboard`
                }
            },
            {
                name:"settings",
                path:"/settings/:course_code",
                component:Settings,
                meta:{
                    middleware:"auth",
                    title:`Settings`
                }
            },
            { path: "*", component: Main }
        ]
    }
]

var router  = new VueRouter({
    mode: 'history',
    routes: Routes
})
//console.log(this);
router.beforeEach((to, from, next) => {
    document.title = "Community"+` - ${to.meta.title}`
    
    if(to.meta.middleware == "guest"){
        
        if(store.state.auth.authenticated){
        //    next({name:"dashboard"})
            if(to.query.community){
                if(to.query.token){
                    next();
                }else{
                    localStorage.setItem('community',to.query.community);
                    next({name:"threads",params:{course_code:to.query.community,type:'all'}})
                }
            }else{
                next({name:"threads",params:{course_code:'main',type:'all'}})
            }
        }
        next();
    }else{ 
        if(store.state.auth.authenticated){
            next();
        }else{
            next({name:"login"})
        }
    }
})

export default router