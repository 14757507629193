<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
    import { EventBus } from "./event-bus.js";
    import './assets/style.css';
    export default {
      name: 'App',
      data:() => ({
        isLoading : false
      }),
      created:function(){
      // console.log(this.$router.options.history);
        if(!localStorage.getItem('base_url')){
            var logoutUrl = (process.env.VUE_APP_Enviroment == 'local') ? process.env.VUE_APP_Local_URL : process.env.VUE_APP_Live_URL;
            window.location.href = logoutUrl+'/home/c_logout';
        }
      }
    }
</script>
<style>
    /* **** Custom Styling ***** */

    html {
        background: #f8f7fa;
    }
    body {
        background: #f8f7fa;
        padding: 16px 9px !important;
    }
    .btn-primary, .btn-default, .btn-default.btn-primary {
        color: #fff;
        background-color: #7367f0;
        border-color: #7367f0;
    }
    .btn.btn-default {
        box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.4);
        cursor: pointer;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        transition: all 0.135s ease-in-out;
        padding: 5px 1.25rem;
        font-size: 0.9375rem;
        line-height:1.125;
        font-weight: 500;
        border-radius: 0.375rem;
    }
    .btn-outline-primary, .btn-outline-default, .btn-outline-default.btn-outline-primary {
        color: #7367f0;
        background-color: transparent;
        border: 1px solid #7367f0;
    }
    .btn.btn-outline-secondary.btn-outline-default {
        margin: 0px !important;
        margin-left: 10px !important;
        font-size: 0.9375rem !important;
        line-height: 1.125;
        font-weight: 500;
        border-radius: 0.375rem !important;
        color: #a8aaae !important;
        background-color: #f8f8f9 !important;
        border: 1px solid #a8aaae !important;
    }
    .btn.btn-primary.deleteThread {
        background-color: #7367f0 !important;
        color: #fff;
        border-color: #7367f0;
    }
    .btn.btn-outline-secondary.btn-outline-default.cancelBtn {
        margin-left: 0px !important;
        font-weight: normal;
    }
    .btn.btn-primary.deleteThread:hover {
        box-shadow: 0px 10px 20px -10px #7367f0 !important
    }
    .btn.btn-default.noContent {
        padding: 13px 1rem !important;
    }
    .btn-label-warning {
        color: #ff9f43 !important;
        border-color: transparent !important;
        background: #fff0e1 !important;
    }
    .btn-label-warning:hover, .btn-label-warning:active, .btn-label-warning:focus {
        color: #fff !important;
        border-color: #ff9f43 !important;
        background: #ff9f43 !important;
    }
    .card.general-card, .vs-card {
        box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
        border-radius: 0.375rem !important;
        color: #6f6b7d !important;
    }
    .vs-card {
        padding: 1.25rem;
    }
    .general-card .card-header {
        padding: 1.25rem 1.25rem 0;
        box-shadow: none !important;
    }
    .general-card .card-body {
        padding: 1.25rem;
    }
    .vs-avatar-content {
        border-radius: 50% !important;
    }
    .mb-3 {
        margin-bottom: 1.25rem !important;
    }
    .alert {
        font-weight: 500;
        padding: 0.687rem 0.875rem;
        border-radius: 0.375rem;
        width: 100%;
    }
    .alert-warning {
        background-color: #fff0e1;
        border: 1px solid #fff0e1;
        color: #ff9f43;
    }
    .alert-danger {
        background-color: #fce4e4;
        border: 1px solid #fce4e4;
        color: #ea5455;
    }
    .alert-primary {
        background-color: #e9e7fd;
        border: 1px solid #e9e7fd;
        color: #7367f0;
    }
    .alert-success {
        background-color: #ddf6e8;
        border: 1px solid #ddf6e8;
        color: #28c76f;
    }
    .alert-info {
        background-color: #d6f7fb;
        border: 1px solid #d6f7fb;
        color: #00cfe8;
    }
    .giffy-branding.branding-col, .searchBox-col, .close-col {
        flex: 0 0 auto;
        width: fit-content !important;
        position: relative;
    }
    .giffy-searchBox {
        position: relative;
    }
    .comment-box > div:not(.reply_boxs) {
        background: transparent !important;
        margin-left: 20px !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }
    @media(max-width: 550px) {
        .giffy-branding.branding-col, .searchBox-col {
            width: 50% !important;
        }
        .close-col {
            order: 1;
            width: 100% !important;
        }
    }
    /* **** / Custom Styling ***** */
    .ql-toolbar.ql-snow {
        border: 1px solid transparent !important;
        border-bottom: 1px solid #cccccc !important;
        right:48px;
        z-index:1;
        background: white;
        text-align: center;
        vertical-align: middle;
        border-radius: 0.375rem;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow{
        border:1px solid #ccc !important;
        border-radius: 15px;
    }
    #readmore{
        display: block;
        margin-left: 0px;
        padding: 4px 7px;
        border-radius: 0.375rem;
        background: transparent;
        border:1px solid #7367f0;
        color:#7367f0;
    }
    #readmore:hover{
      background: #7367f0;
      border:1px solid #7367f0;
      color:#fff;
    }
    /* .gamefication-box img:hover {
        transform: scale(2);
        z-index: 1;
        position:absolute
    }*/
    .newdiscussion .ql-snow .textarea-emoji-control{
        position: sticky !important;
        right: 35px !important;
        bottom: 6px !important;
        float: right !important;
        width: 21px !important;
        display: flow-root !important;
    }
    .small{
        font-size:12px !important;
    }
    .textarea-emoji-control{
        bottom: 8px;
        top: auto !important;
        z-index:1 !important;
        cursor:pointer;
        right:45px !important;
    }
    .w-95{
        width: 95%;
    }
    a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover{
        text-decoration:none !important;
    }
    a{
        text-decoration:none !important;
    }
    .vs-dialog-content{
        padding-bottom:0px !important;
    }
    .vs-dialog{
        margin-bottom:0px !important;
    }
    .community-container{
        justify-content:center !important;
    }
    .vs-row{
        padding: 10px;
        text-align: center;
        background: #eef2f5;
        border-left: 2px solid #eef2f5;
        border-bottom: 2px solid #fff;
        cursor: default;
        transition: all .25s ease;
        box-shadow: inset 0 0 0 0 rgb(0 0 0 / 10%);
    }
    .white-bg{
        background: #fff;
    }
    .mh-15{
        min-height: 15rem;
    }
    .common-class{
        background: #eef2f5 ;
        margin: 15px;
        border-radius: 15px;
        padding: 1rem;
    }
    .mh-50{
        min-height: 50rem;
    }
    .vs-card:hover{
        -webkit-transform: none !important; 
        transform: none !important;
    }
    .vs-card{
        cursor: default !important;
    }
    .card_title span{
        text-align: initial;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 62px;
    }
    .ql-mention-list-container{
        z-index:1 !important;
    }
    .ql-mention-list{
        background: white;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        color: inherit;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid lightgray;
        overflow-y: auto;
        max-height: 150px;
    }
    .ql-mention-list li:hover{
        color:#0366d6;
        cursor:pointer
    }
    .members_list{
        display:flex;
        justify-content:center;
        align-items: center;
        margin-bottom: 5px;
    }
    .members_list img{
        max-width:25px;
        border-radius:50px;
        margin-right: 5px;
    }
    .mention{
        background: #d3e1eb;
        padding: 5px;
        border-radius: 10px;
    }
    .disucssion_card .vs-card .vs-card__img{
        max-width: 55px;
        width: 55px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50px !important;
        margin: 0px !important;
        max-height: 55px !important;
    }
    .disucssion_card .vs-card .vs-card__img img{
        min-width: 55px !important;
        width: 55px !important;;
        height: 55px;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        padding: 5px;
    }
    .disucssion_card .vs-card-content.type-3 .vs-card__text{
        padding-left: 0px !important;
    }
    .disucssion_card .vs-card .vs-card__text{
        padding: 0px !important;
        padding-left: 1rem !important;
        width: 100%;
    }
    .disucssion_card .vs-card .vs-card__text p{
        text-align: initial;
        color: #6f6b7d !important;
    }
    .messagecount{
        padding:0px !important;
    }
</style>
